
.react-datepicker__tab-loop {
  display: inline-block;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.datePicker {
  display: inline-block;
  max-width: 100%;
  width: 120px;
}

.hourPicker {
  display: inline-block;
  max-width: 100%;
  width: 70px;
}

.inlineButton {
  margin-bottom: 5px;
}

.botTextTemplateInput {
  font-size: 13px;
}

.templateSelect {
  display: inline-block;
  max-width: 100%;
  width: 190px;
}