.lineupGrid {
  display: inline-block;
  position: relative;
}

.lineupGrid.hide {
  display: none;
}

.lineupGridRow {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: row;
}

.lineupGridRow.header {
  display: inline-block;
  border-right: 1px solid #000000; 
  border-left: 1px solid #000000; 
  border-bottom: 1px solid #000000;
  padding: 5px;
  width: 100%;
  text-align: center;
  background-color: rgb(255, 170, 0);
  position: sticky;
  top: 0px;
  z-index: 75;
}

.lineupGridRow.time:nth-child(odd) {
  background-color: rgb(237, 237, 237);
}

.lineupGridRow .lineupCell {
  display: inline-block;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000; 
  padding: 2px;
}

.lineupGridRow:last-child .lineupCell {
  border-bottom: 1px solid #000000;
}

.lineupGridRow .lineupCell:first-child {
  border-left: 1px solid #000000;
}

.lineupGridRow .lineupCell.timeCell {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  min-width: 100px;
}

.lineupGridRow .lineupCell.availabilityCell {
  text-align: center;
  border-right: none;
  flex-grow: 0;
  flex-shrink: 0;
}

.lineupGridRow .lineupCell.lineupSlotCell {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0;
}

.lineupGridRow .lineupCell.lineupSlotCell .form-select {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  width: 106px;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.lineupGridRow .lineupCell.availabilityCell span {
  padding: 0 5px 2px;
  border-radius: 10px;
}

.lineupGridRow .lineupCell.availabilityCell .prefered {
  background-color: rgb(0, 203, 0) !important;
  color: #ffffff;
}

.lineupGridRow .lineupCell.availabilityCell .available {
  background-color: rgb(238, 255, 0);
}

.lineupGridRow .lineupCell.lineupSlotCell .form-select .prefered
 {
  background-color: rgb(0, 203, 0);
  color: #ffffff;
}

.lineupGridRow .lineupCell.lineupSlotCell .form-select .available
 {
  background-color: rgb(238, 255, 0);
}


.lineupGridRow .lineupCell.lineupSlotCell .form-select .unassigned {
  background-color: #ffffff;
  color: #000000;
}

.lineupGridRow .selectionOverlay {
  position:absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 155, 255, .35);
  top: 0;
  left: 0;
  pointer-events: none;
}
