
.mobileDropdown {
  position: relative;
  width: 100%;
  height: 100%;
}

.mobileDropdownSelected {
  width: 100%;
  height: 100%;
}

.mobileDropdownOptions li,
.mobileDropdownSelected {
  padding: 2px 6px;
}

.mobileDropdown .mobileDropdownOptions {
  padding: 0;
  margin: 0;
  position: absolute;
  right: -3px;
  background-color: #ffffff;
  z-index: 200;
  min-width: 106%;
  box-shadow: 0px 4px 15px #4e4e4e;
}

.mobileDropdown .mobileDropdownOptions li {
  list-style-type: none;
  border: 1px solid #000000;
  padding: 2px 6px;
  height: 29px;
}

.mobileDropdown .mobileDropdownOptions li.highlighted {
  background-color: blue !important;
  color: #ffffff !important;
}