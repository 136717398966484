.signupBottomBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #888888;
  height: 63px;
}

.signupBottomBar .signupAction {
  width: 100%;
  height: 63px;
  border-radius: 0;
  border-width: .5px;
  margin: 0;
}

.signupBottomBar .signupAction.prefered {
  background-image: linear-gradient(rgb(109 228 109), rgb(2 189 2));
  border-Color: rgb(0, 0, 0);
}

.signupBottomBar .signupAction.prefered:hover {
  background-image: linear-gradient(rgb(109 228 109), rgb(4 199 4));
}

.signupBottomBar .signupAction.prefered:active {
  background-image: linear-gradient(rgb(0, 203, 0), rgb(109 228 109));
}

.signupBottomBar .signupAction.available {
  background-image: linear-gradient(rgb(249 255 164), rgb(208 223 0));
  border-Color: rgb(0, 0, 0);
  color: #000000;
}

.signupBottomBar .signupAction.available:hover {
  background-image: linear-gradient(rgb(249 255 164), rgb(217 232 0));
}

.signupBottomBar .signupAction.available:active {
  background-image: linear-gradient(rgb(238, 255, 0), rgb(249 255 164));
}

.signupBottomBar .signupAction.unavailable {
  background-image: linear-gradient(rgb(255,255,255), rgb(209 209 209));
  border-Color: rgb(0, 0, 0);
  color: #000000;
}

.signupBottomBar .signupAction.unavailable:hover {
  background-image: linear-gradient(rgb(255,255,255), rgb(224 224 224));
}

.signupBottomBar .signupAction.unavailable:active {
  background-image: linear-gradient(rgb(230 230 230), rgb(255,255,255));
}

.signupBottomBar .signupAction:disabled {
  background-image: linear-gradient(rgb(219 217 217), rgb(102 102 102));
  color: #000000;
}