.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  box-shadow: 0px 0px 5px #888888;
  z-index: 100;
}

.navbarContainer {
  padding-bottom: 56px;
}

.navbar-collapse.collapse.show,
.navbar-collapse.collapsing {
  --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0px 4px 5px #888888;
}