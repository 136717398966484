.viewLineup {
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px #888888;
  margin: 0 auto;
  max-width: 100%;
}

.viewLineup.hide {
  display: none;
}

.viewLineupRow {
  position: relative;
  user-select: none;
}

.viewLineupRow td {
  padding: 10px;
}

.viewLineupRow.header {
  display: inline-block;
  border-right: 1px solid #000000; 
  border-left: 1px solid #000000; 
  border-top: 1px solid #000000; 
  padding: 5px;
  width: 100%;
  text-align: center;
  background-color: rgb(255, 170, 0);
}

.viewLineupRow.currentUser { 
  color: #002bff;
  font-weight: bold;
}

.viewLineupRow.time:nth-child(odd) {
  background-color: rgb(237, 237, 237);
}

.viewLineupRow .lineupCell {
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
}

.viewLineupRow:last-child .lineupCell {
  border-bottom: 1px solid #000000;
}

.viewLineupRow .lineupCell:first-child {
  border-left: 1px solid #000000;
}

.viewLineupRow .lineupCell.timeCell {
  text-align: center;
  min-width: 100px;
}

.viewLineupRow .lineupCell.lineupSlotCell {
  
}

.viewLineupRow .lineupCell.lineupSlotCell .form-select {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  width: 106px;
}

.viewLineupRow .lineupCell.availabilityCell span {
  padding: 0 5px 2px;
  border-radius: 10px;
}

.viewLineupRow .lineupCell.availabilityCell .prefered {
  background-color: rgb(0, 203, 0) !important;
  color: #ffffff;
}

.viewLineupRow .lineupCell.availabilityCell .available {
  background-color: rgb(238, 255, 0) !important;
}

@media (max-width: 400px) {
  .viewLineupRow .lineupCell.timeCell {
    font-size: 11px;
  }
}
