.signupGridRow {
  padding-top: 56px;
  padding-bottom: 63px; 
}

.titleCol {
  border-bottom: 1px solid #000000;
  padding-top: 10px;
  position: fixed;
  top: 56px;
  width: 100%;
  height: 56px;
  z-index: 99;
  color: #000000;
  background-color: #ffe1c1;
}

.titleCol .scrollbarSignupTitle {
  white-space: nowrap;
}

.containerCol {
  overflow-x: hidden;
}

.flexContainer {
  display: flex;
  flex-direction: row;
}

.flexContainer .otherUsersCol {
  flex-grow: 1;
  flex-shrink: 1;
}

.flexContainer .lineupCol {
  flex-grow: 0;
  flex-shrink: 0;
}

.lineupCol {
  box-shadow: -4px 0px 10px #888888;
  background-color: #ffffff;
  position: relative;
}

.collapseBtn {
  position: absolute;
  top: 50vh;
  text-align: center;
  border: 1px solid #888888;
  border-right: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px #888888;
  font-weight: bold;
  font-size: 30px;
  height: 55px;
  width: 35px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  clip-path: inset(-60px 0px -60px -60px);
}

.lineupCol .timeGrid .username {
  border-right: none;
}

.otherUsersCol {
  white-space: nowrap;
}

.scrollbarSignupVertical {
  height: calc(100vh - 175px) !important;
}

.scrollbarSignupOtherUsers.fullWidth {
  height: calc(100vh - 175px) !important;
}

.scrollbarSignupOtherUsers > div:last-child {
  display: none;
}

.scrollbarSignupOtherUsers.fullWidth > div:last-child {
  display: block;
}

.hide {
  display: none;
}