.timeGrid {
  display: inline-block;
  position: relative;
}

.timeGrid.hide {
  display: none;
}

.timeGridRow {
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: row;
}

.timeGridRow.username {
  display: inline-block;
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding: 5px;
  min-width: 150px;
  width: 100%;
  text-align: center;
  background-color: rgb(255, 170, 0);
  position: sticky;
  top: 0px;
  z-index: 75;
}

.timeGridRow.username.hasNotes {
  background-color: #52ebff;
}

.timeGridRow.time:nth-child(odd) {
  background-color: rgb(237, 237, 237);
}

.timeGridRow .signupCell {
  display: inline-block;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000; 
  padding: 2px;
}

.timeGridRow:last-child .signupCell {
  border-bottom: 1px solid #000000;
}

.timeGridRow .signupCell:first-child {
  border-left: 1px solid #000000;
}

.timeGridRow .signupCell.timeCell {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}

.timeGridRow .signupCell.timeCell.noSignupNumbers {
  width: 150px;
}

.timeGridRow.prefered .timeCell {
  background-color: rgb(0, 203, 0) !important;
  color: #fff;
}

.timeGridRow.available .timeCell {
  background-color: rgb(238, 255, 0) !important;
}

.timeGridRow.unavailable .timeCell {
  
}

.timeGridRow .signupCell.availabilityCell {
  text-align: center;
  border-right: none;
  flex-grow: 0;
  flex-shrink: 0;
}

.timeGridRow .signupCell.availabilityCell span {
  padding: 0 5px 2px;
  border-radius: 10px;
}

.timeGridRow .signupCell.availabilityCell .prefered {
  background-color: rgb(0, 203, 0) !important;
  color: #ffffff;
}

.timeGridRow .signupCell.availabilityCell .available {
  background-color: rgb(238, 255, 0) !important;
}

.timeGridRow .selectionOverlay {
  position:absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 155, 255, .35);
  top: 0;
  left: 0;
  pointer-events: none;
}

.timeGrid .colSelectionOverlay {
  position:absolute;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 155, 255, 1);
  top: 0;
  left: 0;
  pointer-events: none;
}

.timeGrid .timeGridRow.username.currentUser,
.timeGrid .timeGridRow.username.hasNotes {
  cursor: pointer;
}

.timeGrid .timeGridRow.username .notesPopup {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 35px;
  background-color: #ffffff;
  width: 99.5%;
  border: 1px solid black;
  border-top: 4px solid #ffffff;
}

.timeGrid .timeGridRow.username .notesPopup.open {
  border-top: 4px solid #52ebff;
}

.timeGrid .timeGridRow.username .notesPopup p {
  white-space: normal;
}

.timeGrid .timeGridRow.username .notesPopup .txtEditNotes {
  width: 94%;
  margin: 3%;
}

.timeGrid .timeGridRow.username .notesPopup .btn {
  width: 100%;
  border-radius: 0;
  margin: 0;
  height: 50px;
}
