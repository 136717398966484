.lineupBottomBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #888888;
  height: 63px;
}

.lineupBottomBar .setAction {
  width: 100%;
  height: 63px;
  border-radius: 0;
  border-width: .5px;
  margin: 0;
}

.lineupBottomBar .setAction.set {
  background-image: linear-gradient(rgb(109 228 109), rgb(2 189 2));
  border-Color: rgb(0, 0, 0);
}

.lineupBottomBar .setAction.set:hover {
  background-image: linear-gradient(rgb(109 228 109), rgb(4 199 4));
}

.lineupBottomBar .setAction.set:active {
  background-image: linear-gradient(rgb(0, 203, 0), rgb(109 228 109));
}

.lineupBottomBar .setAction.clear {
  background-image: linear-gradient(rgb(255 107 107), rgb(228 1 1));
  border-Color: rgb(0, 0, 0);
}

.lineupBottomBar .setAction.clear:hover {
  background-image: linear-gradient(rgb(255 107 107), rgb(241 1 1));
}

.lineupBottomBar .setAction.clear:active {
  background-image: linear-gradient(rgb(228 1 1), rgb(255 107 107));
}

.lineupBottomBar .setAction:disabled {
  background-image: linear-gradient(rgb(219 217 217), rgb(102 102 102));
  color: #000000;
}